<template>
  <base-modal :formTitle="formTitle">
    <template v-slot:body>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            v-model="marca.descricao"
            :rules="[
              () => !!marca.descricao || 'Esse campo é obrigatório!',
            ]"
            ref="descricao"
            label="Descrição"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:buttons>
      <buttons-cadastro @close="close" @save="save" />
    </template>
  </base-modal>
</template>
<script>
import { mapActions } from 'vuex'
import BaseModal from '@/components/BaseModal/BaseModal.vue'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue'

export default {
  components: { BaseModal, ButtonsCadastro },
  name: 'CadastroMarca',
  props: {
    marca: Object,
    formTitle: String,
    marcaIndex: Number,
  },

  data() {
    return {
      formError: false,
      defaultItem: {
        descricao: '',
      },
    }
  },

  computed: {
    form() {
      return {
        descricao: this.marca.descricao,
      }
    },
  },

  methods: {
    ...mapActions('patio', [
      'addMarca',
      'editMarca',
      'deleteMarca',
    ]),

    close() {
      this.formError = false
      setTimeout(() => {
        Object.assign(this.marca, this.defaultItem)
        this.resetarFormulario()
      }, 300)

      this.$emit('close')
    },

    resetarFormulario() {
      this.formError = false

      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset()
      })
    },

    verificaFormulario() {
      this.formError = false

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formError = true

        this.$refs[f].validate(!this.form[f])
      })
    },

    save() {
      this.verificaFormulario()
      if (this.formError) return

      if (this.marcaIndex > -1) {
        this.editMarca(this.marca)
      } else {
        this.addMarca(this.marca)
      }

      this.close()
    },
  },
}
</script>
<style>
</style>