<template>
  <div>
    <div class="mt-4">
      <v-data-table
        :headers="headers"
        :items="marcas"
        sort-by="descricao"
        class="border"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Marcas registradas</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon v-if="canList" medium @click="getMarcas" title="Atualizar"
              >mdi-refresh</v-icon
            >
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="canCreate"
                  color="primary"
                  dark
                  class="mb-2"
                  v-on="on"
                  >Nova Marca</v-btn
                >
              </template>
              <cadastro-marca
                :marca="marca"
                :formTitle="formTitle"
                @close="close"
                :marcaIndex="marcaIndex"
              />
            </v-dialog>

            <v-dialog v-model="dialogDelete" max-width="400px">
              <v-card>
                <v-card-title class="v-card mb-4">
                  <span class="headline">Deletar marca</span>
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="black darken-1" text @click="closeDeleteItem"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="darken-1"
                    style="background: #f00; color: white"
                    @click="deletarMarca"
                    >Deletar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="canUpdate" small class="mr-2" @click="editItem(item)"
            >mdi-pencil</v-icon
          >
          <v-icon v-if="canDelete" small @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
        </template>

        <template v-slot:no-data>
          <v-btn v-if="canList" color="primary" @click="getMarcas">Reset</v-btn>
          <div v-if="!canList">
            <span class="red--text"
              >Você não tem permissão para visualizar as informações desta
              tela</span
            >
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CadastroMarca from './CadastroMarca.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  components: { CadastroMarca },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      marcaIndex: -1,
      marca: {
        descricao: '',
      },
    }
  },
  computed: {
    ...mapState('patio', ['marcas']),

    permission() {
      return Sequences.SistemaVeiculoMarcas.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },

    formTitle() {
      return this.marcaIndex === -1 ? 'Adicionar nova marca' : 'Editar marca'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    if (this.canList) {
      await this.getMarcas()
    }
  },

  methods: {
    ...mapActions('patio', [
      'getMarcas',
      'addMarca',
      'editMarca',
      'deleteMarca',
    ]),

    editItem(item) {
      this.marcaIndex = this.marcas.indexOf(item)
      Object.assign(this.marca, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.dialogDelete = true
      this.marcaIdDelete = item.id
    },

    closeDeleteItem() {
      this.dialogDelete = false
      this.marcaIdDelete = -1
    },

    deletarMarca() {
      if (this.marcaIdDelete > -1) {
        this.deleteMarca(this.marcaIdDelete)
      }
      this.marcaIdDelete = -1
      this.dialogDelete = false
    },

    close() {
      this.marcaIndex = -1
      this.dialog = false
    },
  },
}
</script>
